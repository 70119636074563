// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-consult-js": () => import("./../../../src/pages/book-consult.js" /* webpackChunkName: "component---src-pages-book-consult-js" */),
  "component---src-pages-consult-js": () => import("./../../../src/pages/consult.js" /* webpackChunkName: "component---src-pages-consult-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infused-js": () => import("./../../../src/pages/infused.js" /* webpackChunkName: "component---src-pages-infused-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-wedding-flowers-beamsville-js": () => import("./../../../src/pages/wedding-flowers-beamsville.js" /* webpackChunkName: "component---src-pages-wedding-flowers-beamsville-js" */),
  "component---src-pages-wedding-flowers-fonthill-js": () => import("./../../../src/pages/wedding-flowers-fonthill.js" /* webpackChunkName: "component---src-pages-wedding-flowers-fonthill-js" */),
  "component---src-pages-wedding-flowers-fort-erie-js": () => import("./../../../src/pages/wedding-flowers-fort-erie.js" /* webpackChunkName: "component---src-pages-wedding-flowers-fort-erie-js" */),
  "component---src-pages-wedding-flowers-grimsby-js": () => import("./../../../src/pages/wedding-flowers-grimsby.js" /* webpackChunkName: "component---src-pages-wedding-flowers-grimsby-js" */),
  "component---src-pages-wedding-flowers-jordan-ontario-js": () => import("./../../../src/pages/wedding-flowers-jordan-ontario.js" /* webpackChunkName: "component---src-pages-wedding-flowers-jordan-ontario-js" */),
  "component---src-pages-wedding-flowers-pelham-js": () => import("./../../../src/pages/wedding-flowers-pelham.js" /* webpackChunkName: "component---src-pages-wedding-flowers-pelham-js" */),
  "component---src-pages-wedding-flowers-port-colborne-js": () => import("./../../../src/pages/wedding-flowers-port-colborne.js" /* webpackChunkName: "component---src-pages-wedding-flowers-port-colborne-js" */),
  "component---src-pages-wedding-flowers-port-dalhousie-js": () => import("./../../../src/pages/wedding-flowers-port-dalhousie.js" /* webpackChunkName: "component---src-pages-wedding-flowers-port-dalhousie-js" */),
  "component---src-pages-wedding-flowers-smithville-js": () => import("./../../../src/pages/wedding-flowers-smithville.js" /* webpackChunkName: "component---src-pages-wedding-flowers-smithville-js" */),
  "component---src-pages-wedding-flowers-thorold-js": () => import("./../../../src/pages/wedding-flowers-thorold.js" /* webpackChunkName: "component---src-pages-wedding-flowers-thorold-js" */),
  "component---src-pages-wedding-flowers-vineland-js": () => import("./../../../src/pages/wedding-flowers-vineland.js" /* webpackChunkName: "component---src-pages-wedding-flowers-vineland-js" */),
  "component---src-pages-wedding-flowers-virgil-js": () => import("./../../../src/pages/wedding-flowers-virgil.js" /* webpackChunkName: "component---src-pages-wedding-flowers-virgil-js" */),
  "component---src-pages-wedding-flowers-wainfleet-js": () => import("./../../../src/pages/wedding-flowers-wainfleet.js" /* webpackChunkName: "component---src-pages-wedding-flowers-wainfleet-js" */),
  "component---src-pages-wedding-flowers-welland-js": () => import("./../../../src/pages/wedding-flowers-welland.js" /* webpackChunkName: "component---src-pages-wedding-flowers-welland-js" */)
}

